<div class="second_header">
  <div class="back_button" routerLink="/home">←back</div>
  <div class="title_container">
    <h2>Privacy Policy</h2>
  </div>
</div>

<h1>The party responsible within the meaning of the General Data Protection Regulation (GDPR):</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<div>Wuuush.com OÜ</div>
<div>Mirta 44</div>
<div>13516 Tallinn</div>
<div>Estonia</div>
<div>Email: info@wuuush.com</div>
<br>

<h1>Data protection officer:</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<div>Wuuush.com OÜ</div>
<div>Chief Officer Data Protection</div>
<div>13516 Tallinn</div>
<div>Estonia</div>
<div>Email: info@wuuush.com</div>
<br>

<h1>Data protection</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<div>We appreciate you visiting our website and your interest in the services we offer.</div>
<br>
<div>In this Privacy Policy, we explain how we collect your personal information, what we do with it, for what purposes and on what legal foundation we do so, and what rights you have on that basis</div>
<br>
<div>Our Privacy Statement on the use of our websites do not apply to your activities on the websites of social networks or other providers that can be accessed using the links on our websites. Please read the data protection provisions on the websites of those providers.</div>
<br>

<h1>Collection and processing of your personal data</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<ul>
  <li>We only store personal data (name, email address) if you provide this data.</li>
  <br>
  <li>You are neither legally nor contractually obligated to share your personal information. However, certain features of our websites depend on the sharing personal information. If you do not provide your personal information in such cases, you may not be able to use those features, or they may be available with limited functionality.</li>
  <br>
</ul>
<br>

<h1>Purposes of use</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<ul>
  <li>We use the personal information only for the service purposes.</li>
</ul>
<br>

<h1>Cookies</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<ul>
  <li>Cookies may be used when you are visiting our websites.</li>
  <br>
  <li>Cookies are small files that are stored on your desktop, notebook or mobile device while you visit a website. Cookies make it possible, for example, to determine whether there has already been a connection between the device and the websites; take into account your preferences, offer you certain functions or recognize your usage-based interests. Cookies may also contain personal data.</li>
  <br>
  <li>Whether and which cookies are used when you visit our websites depends on of cookies that are technically required by website.</li>
  <br>
  <li>The use of cookies also depends on the settings of the web browser you are using. Most web browsers are preset to automatically accept certain types of cookies; however, you can usually change this setting. You can delete stored cookies at any time. You can find out how this works in the browser or device you are using in the manual of the learner.</li>
  <br>
  <li>The consent to, and rejection or deletion of, cookies are tied to the device and also to the respective web browser you use. If you use multiple devices or web browsers, you can make decisions or settings differently.</li>
  <br>
  <li>If you decide against the use of cookies or delete them, you not have access to functions of our websites.</li>
</ul>
<br>

<h1>Security</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<div>We take technical and organizational security measures in order to protect your information managed by us from being tampered with, lost, destroyed or accessed by authorized individuals. We are continuously improving our security measures in line with technological advancements.</div>
<br>

<h1>Rights of the Data Subject</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<ul>
  <li>As a data subject, you have the right of access (Art. 15 GDPR), right to rectification (Art. 16 GDPR), right to erasure (Art. 17 GDPR), right to restriction of processing (Art. 18 GDPR) and right to data portability (Art. 20 GDPR).</li>
  <br>
  <li>If you have consented to the processing of your personal data by us, you have the right to revoke your consent at any time. The legality of processing your personal data before revocation remains unaffected.</li>
  <br>
  <li>Right to object. For reasons relating to your particular situation, you have the right to file an objection at any time to the processing of personal data. If you file an objection, we will continue to process your personal data only if we can document mandatory, legitimate reasons that outweigh your interests, rights and freedoms, or if processing is for the assertion, exercise or defense of legal claims. To the extent we use your personal data for direct marketing based on legitimate interests, you have the right to object at any time without giving reasons.</li>
  <br>
  <li>If you believe that the processing of your personal data violates legal requirements, you have the right to lodge a complaint with a competent data protection supervisory authority (Art. 77 GDPR).</li>
  <br>
</ul>

<h1>Data transmission to third parties or recipients outside the European Economic Area</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<ul>
  <li>No data passing to third parties or third parties outside the European Union.</li>
</ul>