<div class="row clearfix">
  <div *ngIf="!loggedIn" class="logoContainer">
    <div class="logoName">WUUUSH</div>
    <div class="logoDotcom">.COM</div>
  </div>
  <div *ngIf="!loggedIn" class="slogan">
    <div class="row1">The right way</div>
    <div class="row2">to pay</div>
    <div class="row3">for your</div>
    <div class="row4">car wash.</div>
  </div>
  <div *ngIf="!loggedIn">
    <button class="homeButton logIn" routerLink="login">Log in</button>
    <button class="homeButton logIn" routerLink="register">Register account</button>
  </div>
  <div *ngIf = "loggedIn">

    <div *ngIf = "!unfinishedPayment" class="buyCreditRow"> 
      <div class="buyCreditText" (click)="onBuyCreditClick()"><b>Buy Credit</b>
        <mat-icon class="credit">credit_card</mat-icon>
      </div>
    </div>

    <div *ngIf = "unfinishedPayment" class="buyCreditRow"> 
      <div class="buyCreditText" (click)="onBuyCreditClick()"><b>1 Unfinished Payment</b>
        <mat-icon class="credit">credit_card</mat-icon>
      </div>
    </div>

    <div class="cardBox">
      <div class="card" (click)="onWashClick()">
        <mat-icon class="homePage_mat-icon" aria-hidden="false" aria-label="Example home icon" fontIcon="home">local_car_wash</mat-icon>
        <div class="cardText">Wash</div>
      </div>
      <div class="card" (click)="onHistoryClick()">
        <mat-icon class="homePage_mat-icon" aria-hidden="false" aria-label="Example home icon" fontIcon="home">list_alt</mat-icon>
        <div class="cardText">History</div>
      </div>
      <div class="card" (click)="onProfileClick()">
        <mat-icon class="homePage_mat-icon" aria-hidden="false" aria-label="Example home icon" fontIcon="home">perm_identity</mat-icon>
        <div class="cardText">Profile</div>
      </div>
    </div>
    <div *ngIf = "isAdmin" class="sectionTitleBox">
      <div class="sectionLine"></div>
    </div>
    <div *ngIf = "isAdmin" class="cardBox">
      <div class="card" (click)="onUsersClick()">
        <mat-icon class="homePage_mat-icon" aria-hidden="false" aria-label="Example home icon" fontIcon="home">supervisor_account</mat-icon>
        <div class="cardText">Users</div>
      </div>
      <div class="card" (click)="onOrdersClick()">
        <mat-icon class="homePage_mat-icon" aria-hidden="false" aria-label="Example home icon" fontIcon="home">list</mat-icon>
        <div class="cardText">Orders</div>
      </div>
      <div class="card" (click)="onTransactionsClick()">
        <mat-icon class="homePage_mat-icon" aria-hidden="false" aria-label="Example home icon" fontIcon="home">credit_card</mat-icon>
        <div class="cardText">Trans.</div>
      </div>
    </div>
  </div>
</div>