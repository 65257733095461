import {Component, OnInit}                   from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EverypayService, AuthService, MessageService } from 'src/app/services';
import { Router } from '@angular/router';

@Component({ templateUrl: 'buyCredit.component.html'})
export class BuyCreditComponent implements OnInit {

  amount = 0;
  amountSubmitted = false;
  amountConfirmed = false;
  TrustedEverypayURL: SafeResourceUrl;
  paymentReference: string;
  paymentSuccess: boolean = null;
  paymentMessage: string = null;
  deleteCard: boolean = false;

  constructor(
    public fb: FormBuilder,
    private sanitizer: DomSanitizer,
    protected everypayService: EverypayService,
    protected router: Router,
    protected authService: AuthService,
    protected messageService: MessageService
  ){}

  paymentMethods: any = [];
  selectedPaymentMethod = 99;
  onItemChange(value){
    this.selectedPaymentMethod = value;
 }

  paymentForm = this.fb.group({
    amountField: ['', [Validators.required, Validators.min(5), Validators.max(100)]],
  });

  async ngOnInit() {
    window.addEventListener('message', this.handleMessage.bind(this));
    const response = await this.authService.getPaymentMethods();
    if(response) this.selectedPaymentMethod = 0;
    this.paymentMethods = response;
  }

  async onSubmit() {
 
    this.amount = this.amountField.value;
    console.log(this.amount);

    try{
      if (this.amount < 5) return;
      if (this.amount > 100) return;
      else {
        this.amountSubmitted = true;
      } 
      
    }catch (e){}
  
  }

  async getEverypayLink(amount) {
    const response = await this.everypayService.getPaymentLink(amount);
    this.TrustedEverypayURL = this.sanitizer.bypassSecurityTrustResourceUrl(response.payment_link);
    this.paymentReference = response.payment_reference;
    window.location.href = response.payment_link;
  }

  async getEverypayLinkSavedCard(amount){
    const response = await this.everypayService.payWithSavedCard(amount);
    this.TrustedEverypayURL = this.sanitizer.bypassSecurityTrustResourceUrl(response.payment_link);
    this.paymentReference = response.payment_reference;
    window.location.href = response.payment_link;
  }

  get amountField() {
    return this.paymentForm.get('amountField');
  }

  async onConfirm(){
    if (this.selectedPaymentMethod == 99)this.getEverypayLink(this.amount);
    if (this.selectedPaymentMethod == 0)this.getEverypayLinkSavedCard(this.amount);
    this.amountConfirmed = true;
  }

  cancelSubmit(){
    this.amountSubmitted = false;
  }

  async handleMessage(event) {
    this.TrustedEverypayURL = null;
    if (event.data.paymentReference == this.paymentReference) {
      this.paymentSuccess = event.data.paymentResponse['success'];
      this.paymentMessage = event.data.paymentResponse['message'];
      if(this.paymentSuccess) await this.authService.refreshUser();
    }
  }

  initiateDeleteCard(){
    this.deleteCard = true;
  }

  async confirmDeleteCard(){
    this.deleteCard = false;
    await this.authService.deleteSavedCard();
    this.messageService.alertMessage("Your card ****" + this.paymentMethods[0].cardNumber + " has been deleted");
    this.paymentMethods = [];
    this.selectedPaymentMethod = 0;
  }
  
  cancelDeleteCard(){
    this.deleteCard = false;
  }

}