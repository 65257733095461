<div class="second_header">
  <div class="back_button" routerLink="/home">←back</div>
  <div class="title_container">
    <h2>Orders</h2>
  </div>
</div>
<div class="buyCreditRow left"> 
  <div *ngIf = "dateRange != 'today'" class="buyCreditText" (click)="getTodayOrders()"><b>Today</b></div>
  <div *ngIf = "dateRange == 'today'" class="buyCreditText active" (click)="getTodayOrders()"><b>Today</b></div>
  <div *ngIf = "dateRange != 'yesterday'" class="buyCreditText" (click)="getYesterdayOrders()"><b>Yesterday</b></div>
  <div *ngIf = "dateRange == 'yesterday'" class="buyCreditText active" (click)="getYesterdayOrders()"><b>Yesterday</b></div>
  <div class="buyCreditText" style ="margin-left: 25px; white-space: nowrap;"(click)="exportCSV()"><b>Export CSV</b></div>
</div>
<div class="buyCreditRow"> 
  <div *ngIf = "dateRange != 'currentMonth'" class="buyCreditText" (click)="getCurrentMonthValues()"><b>This Month</b></div>
  <div *ngIf = "dateRange == 'currentMonth'" class="buyCreditText active" (click)="getCurrentMonthValues()"><b>This Month</b></div>
  <div *ngIf = "dateRange != 'lastMonth'" class="buyCreditText" (click)="getLastMonthValues()"><b>Last Month</b></div>
  <div *ngIf = "dateRange == 'lastMonth'"class="buyCreditText active" (click)="getLastMonthValues()"><b>Last Month</b></div>
</div>
<div class="row clearfix">  
  <div class="listBoxOrders" *ngFor="let order of orders; let i = index;">
    <div *ngIf = "order.status!='timeout' && order.status!='error'" class="orderUpperPart">
      <div class="email">{{order.user}}</div>
      <div class="amount"><b>{{order.amount}}</b></div>
    </div>
    <div *ngIf = "order.status!='timeout' && order.status!='error'" class="orderLowerPart">
      <div class="createdAt">{{this.getMysqlDate(order.createdAt)}}</div>
      <div class="machine">{{order.machine}}</div>
    </div>

  </div>
</div>