import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { AuthService } from '../services';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(public authService: AuthService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let user;
    try {
      user = await this.authService.getUser().toPromise();
    } catch (e) {
      return false;
    }

    return user.role == 'admin';
  }

}