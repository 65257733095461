import {Component, OnInit}  from '@angular/core';
import {MachineService}     from '../../../services';
import {environment}        from '../../../../environments/environment';
import { AuthService } from '../../../services';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './kadaka_patform_02.component.html'
})
export class Kadaka_platform_02Component implements OnInit {
  constructor (
    public machineService: MachineService,
    protected authService: AuthService,
    private router: Router,
  ) { }
  
  machineURL = environment.machineUrls.Kadaka02;

  loading;
  online = false;
  message;
  amount = 0.5;
  orderCreated = false;
  orderCreateFailed = false;
  orderTimedOut = false;
  orderConfirmed = false;
  orderLabel;
  orderValue = 0;
  notEnougnBalance = false;
  ngOnInit() {
    this.loading = true;
    this.ping_platform02();
  }

  async ping_platform02() { 
    const result =  await this.machineService.ping(this.machineURL);
    if (result["status"] == "connected"){
      this.online = true;
    }else if(result["status"] == "error"){
      this.online = false;
      this.message = result["message"];
    }
    this.loading = false;
    console.log(result["status"]);
  }

  pricePlus(){
    if(this.amount<10){
      this.amount *= 100;
      this.amount += 50;
      this.amount /= 100;
      this.amount =  Math.round(this.amount * 100) / 100;
    }
  }
  priceMinus(){
    if(this.amount>0.5){
      this.amount *= 100;
      this.amount -= 50;
      this.amount /= 100;
      this.amount =  Math.round(this.amount * 100) / 100;
    }
  }

  async createOrderPlatform_02(){
    this.loading = true;
    const response =  await this.machineService.createOrder(this.machineURL,this.amount);
    if (response["status"] == "created"){
      this.orderCreated = true;
      this.notEnougnBalance = false;
      this.orderLabel = response["label"];
      this.orderValue = response["value"]/100;
    }else if(response["status"] == "error"){
      if(response["reason"] == "balance"){
        console.log("Not Enough Balance");
        this.notEnougnBalance = true;
      }
    }
    this.loading = false;
    
    console.log(response);
  }

  async confirmOrderPlatform_02(){
    this.loading = true;
    const response =  await this.machineService.confirmOrder(this.machineURL,this.orderLabel);
    
    if (response["status"] == "confirmed"){
      this.orderConfirmed = true;
      this.orderLabel = response["label"];
      this.orderValue = response["value"]/100;
      await this.authService.refreshUser();
    }else if(response["status"] == "error"){
 
      this.orderConfirmed = false;
      this.orderCreateFailed = true;
      this.message = response["message"];
    }
    this.loading = false;
    
    console.log(response);
  }

  async leavePage(){
    if (!this.loading && this.online && this.orderCreated && !this.orderConfirmed) await this.cancelOrder();
    this.router.navigateByUrl('/kadaka');
  }

  async cancelOrder(){
    this.loading = true;
    const response =  await this.machineService.cancelOrder(this.machineURL,this.orderLabel);
    console.log(response);
    this.loading = false;
  }

}