<div class="second_header">
  <div class="back_button" routerLink="/wash">←back</div>
  <div class="title_container">
    <h4><i>J.Vilmsi 63, Kesklinn</i></h4>
    <h2>Select wash bay</h2>
  </div>
</div>


<div class="row clearfix">
  <img src = "../../../assets/LaagnaTee_600x300.png" class = "cardBox platformList image_map">
  <div class="cardBox">
    <div *ngIf ="!platform_1_Selected" class="card washBay notSelected" (click)="onPlatformSelect(1)"><b>1</b></div>
    <div *ngIf ="platform_1_Selected" class="card washBay selected" (click)="onPlatformSelect(1)"><b>1</b></div>

    <div *ngIf ="!platform_2_Selected" class="card washBay notSelected" (click)="onPlatformSelect(2)"><b>2</b></div>
    <div *ngIf ="platform_2_Selected" class="card washBay selected" (click)="onPlatformSelect(2)"><b>2</b></div>

    <div *ngIf ="!platform_3_Selected" class="card washBay notSelected" (click)="onPlatformSelect(3)"><b>3</b></div>
    <div *ngIf ="platform_3_Selected" class="card washBay selected" (click)="onPlatformSelect(3)"><b>3</b></div>

    <div *ngIf ="!platform_4_Selected" class="card washBay notSelected" (click)="onPlatformSelect(4)"> <b>4</b></div>
    <div *ngIf ="platform_4_Selected" class="card washBay selected" (click)="onPlatformSelect(4)"><b>4</b></div>

    <div *ngIf ="!platform_5_Selected" class="card washBay notSelected" (click)="onPlatformSelect(5)"> <b>5</b></div>
    <div *ngIf ="platform_5_Selected" class="card washBay selected" (click)="onPlatformSelect(5)"><b>5</b></div>

  </div>
</div>
<button *ngIf = "platformSelected" (click)="onNextClick()" class="homeButton next">Next →</button>
<button *ngIf = "!platformSelected" class="homeButton next inactive">Next →</button>