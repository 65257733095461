<div class="second_header">
  <div class="back_button" routerLink="/home">←back</div>
  <div class="title_container">
    <h2>History</h2>
  </div>
</div>
<div class="row clearfix">
  <div class="listBoxOrders" *ngFor="let item of history; let i = index;">

    <div *ngIf="item.machine === undefined" class="orderUpperPart">
      <div class="email">{{item.user}}</div>
      <div class="amount"><b *ngIf = "item.type == 'charge'">-</b><b>{{item.amount}}</b></div>
    </div>
    <div *ngIf="item.machine === undefined" class="orderLowerPart">
      <div class="createdAt">{{this.getMysqlDate(item.createdAt)}}</div>
      <div class="machine">{{item.type}}</div>
    </div>

    <div *ngIf="item.type === undefined" class="orderUpperPart">
      <div class="email">{{item.user}}</div>
      <div class="amount"><b>{{item.amount}}</b></div>
    </div>
    <div *ngIf="item.type === undefined" class="orderLowerPart">
      <div class="createdAt">{{this.getMysqlDate(item.createdAt)}}</div>
      <div class="machine">{{item.machine}}</div>
    </div>

  </div>
</div>