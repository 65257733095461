<div class="second_header registerLogin">
  <div class="title_container">
    <h2>Update password</h2>
  </div>
</div>
<div class="row clearfix">
  <form *ngIf = "!completed" [formGroup]="updatePasswordForm" (ngSubmit)="onSubmit()">
    <div class="input_field">
      <label for="password">Password</label>
      <input [type]="showPasswordchecked ? 'text' : 'password'" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
          <div *ngIf="f.password.errors.pattern">Password must contain at least 8 characters, one capital letter, one number and one special character.</div>
      </div>
      <mat-checkbox (change)="showHidePassword()" >Show Password</mat-checkbox>
    </div>
    <div class="input_field">
      <label for="passwordConfirm">Password Confirm</label>
      <input [type]="showConfirmPasswordchecked ? 'text' : 'password'" formControlName="passwordConfirm" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
      <div *ngIf="submitted && f.passwordConfirm.errors" class="invalid-feedback">
          <div *ngIf="f.passwordConfirm.errors.required">Password is required</div>
          <div *ngIf="f.passwordConfirm.hasError('passwords_not_match')">Passwords don't match</div>
      </div>
      <mat-checkbox (change)="showHideConfirmPassword()" >Show Confirm Password</mat-checkbox>
    </div>

    <div class="input_field">
      <input class="homeButton logIn" type="submit" value="Update"/>
    </div>
  </form>
</div>

<div *ngIf = "submitted && completed">Your password has been updated!</div>