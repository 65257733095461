import {Component, OnInit}                   from '@angular/core';
import {Router, ActivatedRoute}              from '@angular/router';
import {first}                               from 'rxjs/operators';
import {AuthService}                         from '../../services/';
import {FormBuilder, FormGroup, Validators}  from '@angular/forms';

/*  {"username":"registerTestUser@gmail.com","password":"12345678Aa!"}  */

@Component({ templateUrl: 'login.component.html'})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    invalidPassword = false;


    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        public authService: AuthService,


        //private alertService: AlertService
    ) {
        // redirect to home if already logged in

        /*
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
        */
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

    }


    get f() { return this.loginForm.controls; }

    async onSubmit() {
        this.submitted = true;
        this.invalidPassword = false;
        if (this.loginForm.invalid) {
            return;
        }
        try {
            await this.authService
              .login(this.loginForm.value.email, this.loginForm.value.password)
              .toPromise();
              this.router.navigateByUrl('/');
          } catch (e) {
            console.log(e.error.message);
            if(e.error.message == "Invalid login or password"){
                this.invalidPassword = true;
            }
            return false;
          }

    }
}
