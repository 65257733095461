import {environment}  from '../../environments/environment';
import {Injectable}   from '@angular/core';
import {HttpClient}   from '@angular/common/http';


@Injectable({providedIn:'root'})

export class UserService {
  constructor(
    protected http: HttpClient,
  ) {}

  async changeUserStatus (user, status){
    let url = environment.apiUrl + 'admin/users/changeStatus';
    return await this.http.post(url,{user:user,status:status}).toPromise();
  }

}