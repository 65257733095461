<mat-drawer-container class="container" [hasBackdrop]="'true'">


  <mat-drawer class="sidenav" #drawer [mode]="'over'">

    <a class="navBarLink" (click)="routeGoTo('/about'); drawer.toggle()">About</a>    
    <a class="navBarLink" (click)="routeGoTo('/faq'); drawer.toggle()">FAQ</a>   
    <a class="navBarLink" (click)="routeGoTo('/termsconditions'); drawer.toggle()">Terms & Conditions</a>     
    <a class="navBarLink" (click)="routeGoTo('/privacypolicy'); drawer.toggle()">Privacy Policy</a>     

  </mat-drawer>



   
  <div class="header">

    <button class="navBarButton" mat-raised-button (click)="drawer.toggle()">
      <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home">menu</mat-icon>
    </button>
    <div class="header_data">
      <div class="header_upper">
        <div *ngIf="loggedIn" class="first_name">{{userData.firstName}}</div>
        <div *ngIf="loggedIn" class="balance">Balance:</div>
      </div>
      <div class="header_lower">
        <div *ngIf="loggedIn" class="last_name">{{userData.lastName}}</div>
        <div *ngIf="loggedIn" class="value"><b>{{userData.balance.toFixed(2)}} EUR</b></div>
      </div>
    </div>

   

  </div>
   
  <div *ngIf="messageOn" class="message">
    <div class="text"> {{messageValue}}</div>
  </div>
  <div class="form_wrapper">
    <div class="form_container">
      <router-outlet></router-outlet>
    </div>
  </div>

</mat-drawer-container>
