import {Component, OnInit}                   from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services';


@Component({ templateUrl: 'confirm.component.html'})
export class ConfirmComponent implements OnInit {
  public message = 'Your account is successfully activated!';
  email_confirmed: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public authService: AuthService,
  ){}

  async ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    const confirmed = await this.authService.confirmEmail(queryParams);
    if (confirmed) {
      this.email_confirmed = true;
      this.authService.refreshUser();
      this.authService.setLoggedIn(true);
    } else {
      this.message = 'Something went wrong';
    }


  }
}