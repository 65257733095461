import { Component, OnInit} from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { EverypayService } from 'src/app/services';

@Component({
  selector: 'app-everypay-landing',
  templateUrl: './everypay-landing.component.html',
})
export class EverypayLandingComponent implements OnInit {

  orderReference:string;
  paymentReference:string;
  result:string;
  paymentSuccess: boolean = null;
  paymentMessage: string = null;
  loadingPayment = true;
  

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected everypayService: EverypayService,
  ) { }

  async ngOnInit() {
    this.orderReference = this.route.snapshot.queryParamMap.get('order_reference');
    this.paymentReference = this.route.snapshot.queryParamMap.get('payment_reference');
    const response =  await this.everypayService.chargeWithNewCard(this.paymentReference);
    //{"success":true,"message":"You have topped up with 15 euros."}
    console.log (response);

    if(response){
      this.paymentSuccess = response.success;
      this.paymentMessage = response.message;
      this.loadingPayment = false;
    }else{
      this.router.navigateByUrl('/home');
    }

  }
}