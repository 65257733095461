<div class="second_header">
  <div class="back_button" routerLink="/home">←back</div>
  <div class="title_container">
    <h2>Transactions</h2>
  </div>
</div>
<div class="buyCreditRow left"> 
  <div *ngIf = "dateRange != 'today'" class="buyCreditText" (click)="getTodayValues()"><b>Today</b></div>
  <div *ngIf = "dateRange == 'today'" class="buyCreditText active" (click)="getTodayValues()"><b>Today</b></div>
  <div *ngIf = "dateRange != 'yesterday'" class="buyCreditText" (click)="getYesterdayValues()"><b>Yesterday</b></div>
  <div *ngIf = "dateRange == 'yesterday'" class="buyCreditText active" (click)="getYesterdayValues()"><b>Yesterday</b></div>
  <div class="buyCreditText" style ="margin-left: 25px; white-space: nowrap;"(click)="exportCSV()"><b>Export CSV</b></div>
</div>
<div class="buyCreditRow"> 
  <div *ngIf = "dateRange != 'currentMonth'" class="buyCreditText" (click)="getCurrentMonthValues()"><b>This Month</b></div>
  <div *ngIf = "dateRange == 'currentMonth'" class="buyCreditText active" (click)="getCurrentMonthValues()"><b>This Month</b></div>
  <div *ngIf = "dateRange != 'lastMonth'" class="buyCreditText" (click)="getLastMonthValues()"><b>Last Month</b></div>
  <div *ngIf = "dateRange == 'lastMonth'"class="buyCreditText active" (click)="getLastMonthValues()"><b>Last Month</b></div>
</div>
<div class="row clearfix">  
  <div class="listBoxOrders" *ngFor="let transaction of transactions; let i = index;">
    <div class="orderUpperPart">
      <div class="email">{{transaction.user}}</div>
      <div class="amount"><b *ngIf = "transaction.type == 'charge'">-</b><b>{{transaction.amount}}</b></div>
    </div>
    <div class="orderLowerPart">
      <div class="createdAt">{{this.getMysqlDate(transaction.createdAt)}}</div>
      <div class="machine">{{transaction.type}}</div>
    </div>
  </div>
</div>