import 'rxjs/add/operator/mergeMap';
import {Injectable}       from '@angular/core';
import {AuthService}      from '../services';
import {HttpInterceptor, 
        HttpHandler, 
        HttpRequest}      from '@angular/common/http';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    protected authService: AuthService
  ){ }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let token = this.authService.getToken();
    if(token) {
      const authRequest = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token)
      });
      return next.handle(authRequest);
    }

    return next.handle(request);
  }
}
