<div class="second_header">
  <div class="back_button" routerLink="/home">←back</div>
  <div class="title_container">
    <h2>FAQ</h2>
  </div>
</div>

<h1>Sign up</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<div>To get started with Wuuush, open our webapp wuuush.com from device your browser and register your account for free. You can sign up directly on your iOS or Android device, as well as via desktop browser.</div>
<br>
<div>After you register, you will receive an email asking you to confirm your Wuuush account. If you don’t receive this confirmation email, check your spam folder or contact Wuuush support. Once you confirm your account, you will have access to Wuuush service.</div>
<br>

<h1>Payments & Refunds</h1>
<div class="sectionTitleBox">
  <div class="sectionLine"></div>
</div>
<div><b>Buy credit</b></div>
<br>
<div>You can make payment by clicking "Buy Credit" button on your main menu and following further instructions. Minimum credit amount is 5.00 euro.</div>
<br>
<div><b>Manage and view your payment history</b></div>
<br>
<div>You can add credit and see all transactions.</div>
<br>
<div><b>Request a refund</b></div>
<ul>
  <li>You are eligible for a refund payment during 14 days after your initial purchase.</li>
  <br>
  <li>Be aware that cancelling the washing cycle does not initiate the refund process. Please contact customer support directly at support@wuuush.com using the same email address as your Wuuush.com account, and wait for confirmation.</li>
  <br>
  <li>Due to Privacy Policy, we will not be able to process your request if you write to us from a different email address.</li>
  <br>
  <li>Please do not delete your Wuuush.com account before the refund has been completed since it might delay the process.</li>
</ul>