import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EverypayService {

  constructor(    
    protected http: HttpClient,
    protected authService: AuthService
  ) { }

  async getPaymentLink(amount): Promise<any> {
    const payload = { amount };
    return await this.http.post(environment.apiUrl + 'everypay/getPaymentLink',payload).toPromise();
  }

  async chargeWithNewCard(paymentReference): Promise<any>{
    const payload = { paymentReference };
    return await this.http.post(environment.apiUrl + 'everypay/chargeWithNewCard',payload).toPromise();
  }

  async payWithSavedCard(amount): Promise<any>{
    const payload = { amount };
    return await this.http.post(environment.apiUrl + 'everypay/chargeWithSavedCard',payload).toPromise();
  }
  
}