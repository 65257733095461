import {Component,OnInit}       from '@angular/core';
import {AuthService}            from '../../services'
import {Router,ActivatedRoute}  from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  constructor (
    protected authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }
  
  
  loggedIn: boolean;
  isAdmin: boolean;
  public userData;
  
  ngOnInit() {
    this.authService.subscribeToAuthStatus().subscribe(value => {
      this.loggedIn = value;
    });
    this.authService.subscribeToUser().subscribe(user => {
      if (user) {
        this.isAdmin = user.role === 'admin';
        this.userData = user;
      } else {
        this.isAdmin = false;
        this.userData = null;      
      }
    });
  }
  logout(event: MouseEvent) {
    event.preventDefault();
    this.authService.logout();
  }

  onWashClick(){
    this.router.navigateByUrl('/wash');
  }

  onHistoryClick(){
    this.router.navigateByUrl('/history');
  }

  onProfileClick(){
    this.router.navigateByUrl('/profile');
  }

  onUsersClick(){
    this.router.navigateByUrl('/admin/users');
  }

  onOrdersClick(){
    this.router.navigateByUrl('/admin/orders');
  }

  onTransactionsClick(){
    this.router.navigateByUrl('/admin/transactions');
  }

  onBuyCreditClick(){
    this.router.navigateByUrl('/buycredit');
  }
}