import 'rxjs/add/operator/mergeMap';
import {Injectable}        from '@angular/core';
import {AuthService}       from '../services';
import {catchError}        from 'rxjs/operators';
import {of}                from 'rxjs';
import {HttpInterceptor, 
        HttpHandler, 
        HttpRequest, 
        HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    protected authService: AuthService,
    //protected alertService: AlertService
  ){ }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        //this.alertService.error(error);
        throw error;
      })
    );
  }
}
