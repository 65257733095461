<div class="second_header">
  <div class="back_button" (click)="leavePage()">←back</div>
  <div class="title_container">
    <h4><i>J.Vilmsi 63, Kesklinn</i></h4>
    <h2>Platform 4</h2>
  </div>
</div>
<div class="row clearfix">
  <div class="cardBoxOne">
    <div class = "cardOne">
      <mat-icon *ngIf = "loading || !online" class = "bigOne offline" aria-hidden="false" aria-label="Example home icon" fontIcon="home" >looks_4</mat-icon>
      <mat-icon *ngIf = "!loading && online" class = "bigOne" aria-hidden="false" aria-label="Example home icon" fontIcon="home" >looks_4</mat-icon>
      <mat-spinner *ngIf = "loading" diameter = 160></mat-spinner>
      <mat-icon *ngIf = "!loading && !online" class = "network_off" aria-hidden="false" aria-label="Example home icon" fontIcon="home">wifi_off</mat-icon>
      <mat-icon *ngIf = "!loading && online" class = "network_on" aria-hidden="false" aria-label="Example home icon" fontIcon="home">wifi</mat-icon>
    
    
    </div>
  </div>
</div>
<div *ngIf = "!loading && online && !orderCreated" class = "price"><b>{{this.amount}} EUR</b></div>
<div *ngIf = "notEnougnBalance" class = "notEnoughBalance"> Not Enough Balance</div>
<div *ngIf = "!loading && online && orderCreated" class = "price orderValue"><b>{{this.orderValue}} EUR</b></div>
<div *ngIf = "!loading && online" class = "buttonBox">
  <button *ngIf = "!loading && online && !orderCreated && !orderCreateFailed" class = "plusMinus" (click)="priceMinus()"> <b>-</b> </button>
  <button *ngIf = "!loading && online && !orderCreated && !orderCreateFailed" class = "plusMinus" (click)="pricePlus()"> <b>+</b> </button>
</div>
<button *ngIf = "!loading && online && !orderCreated && !orderCreateFailed" class="homeButton pay" (click)="createOrderPlatform_Laagna_04()">Next →</button>
<div class = "price orderConfirmSummary" *ngIf = "!loading && online && orderCreated && !orderConfirmed" >Confirm payment <b>{{this.orderValue}} EUR</b> to <b>Platform 4</b></div>
<div class="price confirmed" *ngIf = "!loading && online && orderCreated && orderConfirmed"><b>Success</b></div>
<button *ngIf = "!loading && online && orderCreated && orderConfirmed" routerLink="/home" class="homeButton next">OK</button>
<button *ngIf = "!loading && online && orderCreated && !orderConfirmed" class="homeButton pay" (click)="confirmOrderPlatform_Laagna_04()">Confirm</button>