import {Component,OnInit} from '@angular/core';
import {Router}           from '@angular/router';

@Component({templateUrl: 'laagna.component.html'})
export class LaagnaComponent implements OnInit {
  
  platformSelected: boolean;
  platform_1_Selected: boolean;
  platform_2_Selected: boolean;
  platform_3_Selected: boolean;
  platform_4_Selected: boolean;
  platform_5_Selected: boolean;

  constructor (
    private router: Router,
  ) {}

  ngOnInit() {}

  resetPlatrormSelect (){
    this.platform_1_Selected = false;
    this.platform_2_Selected = false;
    this.platform_3_Selected = false;
    this.platform_4_Selected = false;
    this.platform_5_Selected = false;
  }

  onNextClick(){
    if(this.platform_1_Selected){
      this.router.navigateByUrl('/laagna/platform01');
    }
    else if(this.platform_2_Selected){
      this.router.navigateByUrl('/laagna/platform02');
    }
    else if(this.platform_3_Selected){
      this.router.navigateByUrl('/laagna/platform03');
    }
    else if(this.platform_4_Selected){
      this.router.navigateByUrl('/laagna/platform04');
    }
    else if(this.platform_5_Selected){
      this.router.navigateByUrl('/laagna/platform05');
    }
  }

  onPlatformSelect(platformNumber){
    if(!this.platformSelected){
      this.platformSelected = true;
    }
    this.resetPlatrormSelect();
    switch(platformNumber) {
      case 1:
        this.platform_1_Selected = true;
        break;
      case 2:
        this.platform_2_Selected = true;
        break;
      case 3:
        this.platform_3_Selected = true;
        break;
      case 4:
        this.platform_4_Selected = true;
        break;
      case 5:
        this.platform_5_Selected = true;
        break;
      default:
    }
  }
}