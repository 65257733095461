<div class="second_header">
  <div class="back_button" routerLink="/home">←back</div>
  <div class="title_container">
    <h2>Profile</h2>
  </div>
</div>

<div class="listUser first">
  <div class="listItem profile">Balance:</div>
  <div class="listItem value profile">{{userData.balance.toFixed(2)}} EUR</div>
</div>

<div class="listUser">
  <div class="listItem profile">First name:</div>
  <div class="listItem value profile">{{userData.firstName}}</div>
</div>

<div class="listUser">
  <div class="listItem profile">Last name:</div>
  <div class="listItem value profile">{{userData.lastName}}</div>
</div>

<div class="listUser">
  <div class="listItem profile">Email:</div>
  <div class="listItem value profile email">{{userData.email}}</div>
</div>

<div class="listUser">
  <div class="listItem profile">Password:</div>
  <div class="listItem value profile">********</div>
</div>

<button (click)="logout($event)" class="homeButton logOut">Log out</button>