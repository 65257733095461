<div class="second_header registerLogin">
  <div class="back_button" routerLink="/home">←back</div>
  <div class="title_container">
    <h2>Update password</h2>
    <h4 *ngIf = "!completed" ><i>Please enter your email address. We'll send you a link to update your password.</i></h4>
  </div>
</div>
<div *ngIf="!completed" class="row clearfix">
  <form [formGroup]="updatePasswordForm" (ngSubmit)="onSubmit()">
    <div class="input_field">
        <label for="email">Email</label>
        <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Please enter a valid email address (Ex: john@domain.com)</div>
        </div>
    </div>

    <div class="input_field">
      <input class="homeButton logIn" type="submit" value="Update password"/>
    </div>
  </form>
</div>
<div *ngIf = "submitted && completed">Activation link sent to <b>{{email}}</b>.</div>
<div *ngIf = "submitted && completed">If you don't recieve email, check your spam folder.</div>