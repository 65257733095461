<div class="second_header registerLogin">
    <div class="back_button" routerLink="/home">←back</div>
    <div class="title_container">
      <h2>Register</h2>
    </div>
</div>
<div class="row clearfix">
  <form *ngIf = "!confirmed" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <div class="input_field">
          <label for="firstName">First Name</label>
          <input type="text" formControlName="firstName"/>
          <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
              <div *ngIf="f.firstName.errors.required">First Name is required</div>
              <div *ngIf="f.firstName.errors.pattern">Letters only!</div>
          </div>
      </div>
      <div class="input_field">
          <label for="lastName">Last Name</label>
          <input type="text" formControlName="lastName"/>
          <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
              <div *ngIf="f.lastName.errors.required">Last Name is required</div>
              <div *ngIf="f.lastName.errors.pattern">Letters only!</div>
          </div>
      </div>
      <div class="input_field">
          <label for="email">Email</label>
          <input type="text" formControlName="email"/>
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Please enter a valid email address (Ex: john@domain.com)</div>
              <div *ngIf="f.email.errors.emailExists">Looks like this email is already taken</div>
          </div>
      </div>
      <div class="input_field">
          <label for="password">Password</label>
          <input [type]="showPasswordchecked ? 'text' : 'password'" formControlName="password"/> 
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.pattern">Password must contain at least 8 characters, one capital letter, one number and one special character.</div>
          </div>
          <mat-checkbox (change)="showHidePassword()" >Show Password</mat-checkbox>
      </div>
      <div class="input_field">
        <label for="confirm_password">Confirm Password</label>
        <input [type]="showConfirmPasswordchecked ? 'text' : 'password'" formControlName="confirm_password"/>
        <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
            <div *ngIf="f.confirm_password.errors.required">Please confirm your password</div>
            <div *ngIf="f.confirm_password.hasError('passwords_not_match')">Passwords don't match</div>
        </div>
        <mat-checkbox (change)="showHideConfirmPassword()" >Show Confirm Password</mat-checkbox>
      </div>
      <div class="input_field">
        <input class="homeButton logIn" type="submit" value="Register"/>
        <button class="homeButton logIn" routerLink="/">Cancel</button>
      </div>
  </form>

  <div *ngIf = "submitted && confirmed">Activation link sent to your email!</div>
  <div *ngIf = "submitted && confirmed">If you don't recieve email, check your spam folder.</div>
</div>

