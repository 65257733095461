export const environment = {
  production: false,
  apiUrl: 'https://api.wuuush.com/',
  machineUrls: {
    Kadaka01:'Machine_01',
    Kadaka02:'Machine_02',
    Kadaka03:'Machine_03',
    Kadaka04:'Machine_04',
    Testing: 'Machine_05',
    Laagna01:'Machine_Laagna_01',
    Laagna02:'Machine_Laagna_02',
    Laagna03:'Machine_Laagna_03',
    Laagna04:'Machine_Laagna_04',
    Laagna05:'Machine_Laagna_05',
  }
};
