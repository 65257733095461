import {of}                          from 'rxjs/observable/of';
import {Injectable}                  from '@angular/core';
import {HttpClient}                  from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, catchError}             from 'rxjs/operators';
import {IUser}                       from '../interfaces/user.interface'
import {environment}                 from '../../environments/environment';

@Injectable({providedIn:'root'})
export class AuthService {

  token: any;
  loggedIn = new BehaviorSubject(false);
  user = new BehaviorSubject(null);

  constructor(
    protected http: HttpClient,
    //protected alertService : AlertService,
  ) {
    let token = localStorage.getItem('token');
    this.token = token ? token : false;
  }

  initialize() {
    if(this.token) {
      return this.getUser().pipe(
        map((user: IUser) => {
          this.user.next(user);
          this.loggedIn.next(true);
        }),
        catchError(error => {
          this.loggedIn.next(false);
          this.user.next(null);
          return of(false);
        })
      ).toPromise();
    }

    return of(false);
  }

  getUser() : Observable<IUser> {
    return this.http.get<IUser>(environment.apiUrl + 'auth/me');
  }

  async refreshUser() {
    const user = await this.getUser().toPromise();
    this.user.next(user);
  }

  async getPaymentMethods(){
    return await this.http.get(environment.apiUrl + 'auth/getPaymentMethods').toPromise();
  }

  async deleteSavedCard(){
    return await this.http.post(environment.apiUrl + 'auth/deleteSavedCard',{}).toPromise();
  }

  subscribeToUser() {
    return this.user.asObservable();
  }

  getToken() {
    return localStorage.getItem('token');
  }

  setToken(token) {
    this.token = token;
    localStorage.setItem('token', token);
  }

  login(email, password) {
    let url = environment.apiUrl + 'auth/login';
    return this.http.post( url,{username: email, password}).pipe(
      map(response => {
        this.setToken(response['access_token']);
        this.loggedIn.next(true);
        this.refreshUser();
      })
    );
  }

  logout() {
    //this.alertService.success('You have been logged out');
    this.setToken(false);
    this.loggedIn.next(false);
    this.user.next(null);
  }

  register(data) {
    return this.http.post(`${environment.apiUrl}auth/register`, data).pipe(
      map(response => {
        //not logging in until we haven't got the email confirmed
        return response;
      }),
      catchError(error => {
        //this.alertService.error(error);
        console.log("Error:" + error);
        throw error;
      })
    );
  }

  emailExists(email) {
    return this.http.post(`${environment.apiUrl}auth/emailExists`, {email});
  }

  subscribeToAuthStatus() {
    return this.loggedIn.asObservable();
  }

  isLoggedIn(value = null) {
    if(value !== null) {
      this.loggedIn.next(value);
    }

    return this.loggedIn.getValue();
  }

  setLoggedIn (status: boolean) {
    this.loggedIn.next(status);
  }

  resetUser() {
    this.user.next(null);
  }

  async confirmEmail(params): Promise<Boolean> {
    const url = `${environment.apiUrl}auth/confirmEmail`;
    let result;
    try {
      result = await this.http.post(url, params).toPromise();
      if(result && result['access_token']) {
        this.setToken(result['access_token']);
        return true;
      }
    } catch (e) {
      console.log('Email not confirmed, something went wrong');
    }

    return false;
  }

  async sendPasswordResetLink(data) {
    let result;
    try {
      result = await this.http.post(`${environment.apiUrl}auth/sendPasswordResetLink`, data).toPromise();
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  changePassword(data) {
    return this.http.post(`${environment.apiUrl}auth/changePassword`, data);
  }

  async pingMachineParam(){
    let machine = environment.machineUrls.Testing
    let url = environment.apiUrl + 'machine/pingMachine_param?machine=' + machine;
    return await this.http.get(url).toPromise();
  }
}