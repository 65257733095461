export * from './register/register.component';
export * from './login/login.component';
export * from './home/home.component';
export * from './wash/wash.component';
export * from './wash/KadakaPlatforms/kadaka_platform_01.component'
export * from './wash/KadakaPlatforms/kadaka_platform_02.component'
export * from './wash/KadakaPlatforms/kadaka_platform_03.component'
export * from './wash/KadakaPlatforms/kadaka_platform_04.component'
export * from './wash/LaagmaPlatforms/laagna_platform_01.component'
export * from './wash/LaagmaPlatforms/laagna_platform_02.component'
export * from './wash/LaagmaPlatforms/laagna_platform_03.component'
export * from './wash/LaagmaPlatforms/laagna_platform_04.component'
export * from './wash/LaagmaPlatforms/laagna_platform_05.component'
export * from './wash/TestingPlatforms/testing_platform_05.component' 
export * from './wash/kadaka.component'
export * from './wash/laagna.component'
export * from './register/email.taken.validator'
export * from './admin/orders/orders.component'
export * from './admin/users/users.component'
export * from './admin/transactions/transactions.component' 
export * from './history/history.component'
export * from './profile/profile.component'
export * from './confirm/confirm.component'
export * from './buyCredit/buyCredit.component'
export * from './about/about.component'
export * from './faq/faq.component'
export * from './termsConditions/termsConditions.component'
export * from './privacyPolicy/privacyPolicy.component'
export * from './everypay-landing/everypay-landing.component'
export * from './password/requests-reset/request-reset.component'
export * from './password/response-reset/response-reset.component'