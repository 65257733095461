<div class="second_header registerLogin">
  <div class="title_container">
    <h2>Login</h2>
  </div>
</div>
<div class="row clearfix">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="input_field">
        <label for="email">Email</label>
        <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
        </div>
    </div>
    <div class="input_field">
        <label for="password">Password</label>
        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            
        </div>
        <div *ngIf="invalidPassword" class="invalid-feedback">Invalid email or password</div>
    </div>
    <div class="forgotPassword">
      <div class="forgotPasswordText" routerLink="/request-password-reset">Forgot Password?</div>
    </div>
    <div class="input_field">
      <input class="homeButton logIn" type="submit" value="Login"/>
        <button class="homeButton logIn" routerLink="/register">Register</button>
    </div>
  </form>
</div>
