<div class="second_header">
  <div class="back_button" routerLink="/home">←back</div>
  <div class="title_container">
    <h2>Terms & Conditions</h2>
  </div>
</div>

<div>You have to use the washing platforms with good manner and responsibility.</div>
<br>
<div>Follow the instructions on site with accuracy.</div>
<br>
<div>No physical involvement allowed.</div>
<br>
<div>The money which is transferred from the card or from bank account to the Wuuush.com system is eligible for full refund to bank account immidetly but not more that 14 days</div>
<br>
<div>Sums which is refunded within the Wuuush.com system is not eligible for refund to bank account and intended to be used only within the system.</div>
<br>
<div>Use the same email address as your Wuuush.com account. Other words we will not be able to process your request if you write to us from a different email address</div>
<br>
<div>Please do not delete your Wuuush.com account before the refund has been completed since it might delay the process.</div>
<br>
<div>If we believe that user doesn’t follow the terms and condition above we reserve the right to not provide any services further on.</div>
<br>
<div>Please contact customer support directly at support@wuuush.com using the same email address as your Wuuush.com account.</div>