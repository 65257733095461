import {Component,OnInit}       from '@angular/core';
import {HttpClient}   from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {AdminTransaction} from '../../../services/adminTransaction.service';
import { UserService } from "../../../services/user.service";
import {FormBuilder, FormGroup, Validators}  from '@angular/forms';

@Component({templateUrl: 'users.component.html'})
export class UsersComponent implements OnInit {
  userSearchForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    protected transaction: AdminTransaction,
    protected user: UserService
  ) {}

  transactionTypes = [
    {value:"refund"},
    {value:"charge"},
    {value:"bank transfer"},
    {value:"bonus"},
  ]
  statusValues = [
    {value:"confirm"},
    {value:"pending"},
    {value:"deleted"}
  ]
  selectedType = [];
  users;
  amounts = [];
  userDetailedInfo = [];
  balanceEditMode = [];
  statusEditMode = [];
  selectedStatus = [];

  submitted = false;
  filter = "sort%5B0%5D=createdAt%2CDESC";
  loadingUsers = false;

  ngOnInit() {
    this.userSearchForm = this.formBuilder.group({
      email: ['', Validators.required]
  });
  }

  async updateUsers(){
    this.loadingUsers = true;
    this.users = await this.http.get(environment.apiUrl + 'admin/users/?' + this.filter).toPromise();
    this.clearSelectType();
    this.loadingUsers = false;
  }

  showUserDetailedInfo(user){
    this.userDetailedInfo[this.users.indexOf(user)] = true;
  }

  hideUserDetailedInfo(user){
    this.userDetailedInfo[this.users.indexOf(user)] = false;
  }

  getMysqlDate(date) {
    let myDate = new Date(date).toLocaleString("et-EE", {
       localeMatcher: "best fit",
       timeZoneName: "short"
     });;
     return myDate.replace(',', ' ')
   }
  initBalanceEdit(user){
    this.clearSelectType();
    this.balanceEditMode[this.users.indexOf(user)] = true;
  }

  cancelBalanceEdit(user){
    this.clearSelectType();
    this.balanceEditMode[this.users.indexOf(user)] = false;
  }

  initStatusEdit(user){
    console.log(user.email + ": status edit initialized");
    this.clearSelectType();
    this.statusEditMode[this.users.indexOf(user)] = true;
  }

  cancelStatusEdit(user){
    this.clearSelectType();
    this.statusEditMode[this.users.indexOf(user)] = false;
  }


  clearSelectType(){
    for (let i = 0; i < this.users.length; i++){
      this.balanceEditMode[i] = false;
      this.statusEditMode[i] = false;
      this.selectedType[i]= "bank transfer";
      this.selectedStatus[i] = "pending";
      this.amounts[i] = 0;
    }
  }

  async confirmBalanceEdit(user){
    if(this.amounts[this.users.indexOf(user)]>0 && this.amounts[this.users.indexOf(user)]<=100){
      let response = await this.transaction.createTransaction(this.selectedType[this.users.indexOf(user)],this.amounts[this.users.indexOf(user)],user.email);
      this.updateUsers();
    }
  }

  async confirmStatusEdit(user){
    let response = await this.user.changeUserStatus(user,this.selectedStatus[this.users.indexOf(user)]);
    this.updateUsers();
  }

  onAmountChange(amount,user){
    this.amounts[this.users.indexOf(user)] = amount;
  }

  async searchUser(email){
    if(email != ""){
      const searchEmail = email.replace('@',"%40");
      this.filter = "filter%5B0%5D=email%7C%7C%24cont%7C%7C" + searchEmail;
      this.updateUsers();
    }
  }

  get f() { return this.userSearchForm.controls; }
  clearUserDetailedInfo(){
    this.users.forEach(user => {
      this.hideUserDetailedInfo(user);
    });
  }

  async onSearchSubmit() {
    if(this.users != undefined){
      this.clearUserDetailedInfo();
      this.clearSelectType();
      this.users = [];
    }
    this.searchUser(this.userSearchForm.value.email);
  }

  async getAllUsers(){
    if(this.users != undefined){
      this.clearUserDetailedInfo();
      this.clearSelectType();
      this.users = [];
    }
    this.filter = "sort%5B0%5D=createdAt%2CDESC";
    this.updateUsers();
  }
}