<div class="second_header">
  <div class="back_button" routerLink="/home">←back</div>
  <div class="title_container">
    <h2>Select location <mat-icon class="location">room</mat-icon> </h2>
  </div>
</div>


<div class="row clearfix">
  <img *ngIf = "kadakaSelected" src = "../../../assets/KadakaTee_600x300.png" class = "cardBox platformList image_map">
  <img *ngIf = "laagnaSelected" src = "../../../assets/LaagnaTee_600x300.png" class = "cardBox platformList image_map">
  <div class="cardBox platformList">

    <div *ngIf = "!kadakaSelected" class="card platform" (click)="onKadakaClick()" >Kadaka tee 3, Mustamäe</div>
    <div *ngIf = "kadakaSelected" class="card platform selected">Kadaka tee 3, Mustamäe</div>
    <div *ngIf = "!laagnaSelected" class="card platform" (click)="onLaagnaClick()" >J.Vilmsi 63, Kesklinn</div>
    <div *ngIf = "laagnaSelected" class="card platform selected">J.Vilmsi 63, Kesklinn</div>
  </div>
</div>
<button *ngIf = "laagnaSelected || kadakaSelected"(click)="onNextClick()" class="homeButton next">Next →</button>
<button *ngIf = "!(laagnaSelected || kadakaSelected)"class="homeButton next inactive">Next →</button>