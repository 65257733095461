import {AuthService, MessageService}        from './services';
import {Router}             from '@angular/router';
import {Component, OnInit}  from '@angular/core';


@Component({
  selector:    'app-root',
  templateUrl: './app.component.html',
  styleUrls:  ['./app.component.scss']
})
export class AppComponent implements OnInit{

  title = 'Isepesula24';

  loggedIn: boolean;
  isAdmin: boolean;
  messageOn:boolean = true;
  messageValue:string;

  public userData;
  constructor (
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService
  ) {}

  async ngOnInit() {
    //this.metaService.setMetaTitle('Laundry-o-mat');

    this.authService.subscribeToAuthStatus().subscribe(value => {
      this.loggedIn = value;
    });
    this.messageService.currentMessage.subscribe(message => {
      this.messageValue = message;
    });
    this.messageService.currentState.subscribe(state => {
      this.messageOn = state;
    });

    this.authService.subscribeToUser().subscribe(user => {
      if (user) {
        this.isAdmin = user.role === 'admin';
        this.userData = user;
      } else {
        this.isAdmin = false;
        this.userData = null;      
      }
    });
  }

  logout(event: MouseEvent) {
    event.preventDefault();
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

  routeGoTo(route){
    this.router.navigateByUrl(route);
  }
}
