import {NgModule}                         from '@angular/core';
import {RouterModule,Routes}              from '@angular/router';
import {AfterLoginGuard,
        BeforeLoginGuard, 
        AdminGuard}                       from './guards';
import {LoginComponent, 
        RegisterComponent, 
        HomeComponent, 
        WashComponent, 
        KadakaComponent, 
        LaagnaComponent,
        Kadaka_platform_01Component,
        Kadaka_platform_02Component,
        Kadaka_platform_03Component,
        Kadaka_platform_04Component,
        Testing_platform_05Component,
        Laagna_platform_01Component,
        Laagna_platform_02Component,
        Laagna_platform_03Component,
        Laagna_platform_04Component,
        Laagna_platform_05Component,
        OrdersComponent,
        UsersComponent,
        TransactionsComponent,
        HistoryComponent,
        ProfileComponent,
        ConfirmComponent,
        BuyCreditComponent,
        AboutComponent,
        FAQComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        EverypayLandingComponent,
        RequestResetComponent,
        ResponseResetComponent}                   from "./components"

const routes: Routes = [
  {path: '',                    component: HomeComponent},
  {path: 'login',               component: LoginComponent},
  {path: 'register',            component: RegisterComponent},
  {path: 'confirm-email',       component: ConfirmComponent},
  {path: 'about',               component: AboutComponent},
  {path: 'faq',                 component: FAQComponent},
  {path: 'termsconditions',     component: TermsConditionsComponent},
  {path: 'privacypolicy',       component: PrivacyPolicyComponent},
  {path: 'request-password-reset',  component: RequestResetComponent},
  {path: 'response-password-reset', component: ResponseResetComponent},
  {path: 'history',             component: HistoryComponent,              canActivate: [AfterLoginGuard]},
  {path: 'profile',             component: ProfileComponent,              canActivate: [AfterLoginGuard]},
  {path: 'wash',                component: WashComponent,                 canActivate: [AfterLoginGuard]},
  {path: 'kadaka/platform01',   component: Kadaka_platform_01Component,   canActivate: [AfterLoginGuard]},
  {path: 'kadaka/platform02',   component: Kadaka_platform_02Component,   canActivate: [AfterLoginGuard]},
  {path: 'kadaka/platform03',   component: Kadaka_platform_03Component,   canActivate: [AfterLoginGuard]},
  {path: 'kadaka/platform04',   component: Kadaka_platform_04Component,   canActivate: [AfterLoginGuard]},
  {path: 'laagna/platform01',   component: Laagna_platform_01Component,   canActivate: [AfterLoginGuard]},
  {path: 'laagna/platform02',   component: Laagna_platform_02Component,   canActivate: [AfterLoginGuard]},
  {path: 'laagna/platform03',   component: Laagna_platform_03Component,   canActivate: [AfterLoginGuard]},
  {path: 'laagna/platform04',   component: Laagna_platform_04Component,   canActivate: [AfterLoginGuard]},
  {path: 'laagna/platform05',   component: Laagna_platform_05Component,   canActivate: [AfterLoginGuard]},
  {path: 'testing/platform05',  component: Testing_platform_05Component,  canActivate: [AfterLoginGuard]},
  {path: 'kadaka',              component: KadakaComponent,               canActivate: [AfterLoginGuard]},
  {path: 'laagna',              component: LaagnaComponent,               canActivate: [AfterLoginGuard]},
  {path: 'buycredit',           component: BuyCreditComponent,            canActivate: [AfterLoginGuard]},
  {path: 'everypay-landing',    component: EverypayLandingComponent,      canActivate: [AfterLoginGuard]},

  {path: 'admin/orders',        component:OrdersComponent,                canActivate: [AdminGuard]},
  {path: 'admin/users',         component:UsersComponent,                 canActivate: [AdminGuard]},
  {path: 'admin/transactions',  component:TransactionsComponent,          canActivate: [AdminGuard]},

  //{path: 'admin/user/:id', component: UserEditComponent,canActivate: [AdminGuard]},
  {path: '**', redirectTo: ''} // otherwise redirect to home
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
