import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators}  from '@angular/forms';
import { AuthService } from 'src/app/services';
AuthService
@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html'
})
export class RequestResetComponent implements OnInit {
  updatePasswordForm: FormGroup;
  submitted = false;
  completed = false;
  email = "";

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) {}
  ngOnInit() {
    this.updatePasswordForm = this.formBuilder.group({
      email: ['',[Validators.required, Validators.email]],
    });
  }
  get f() { return this.updatePasswordForm.controls; }
  async onSubmit(){
    this.submitted = true;
    if (this.updatePasswordForm.invalid) return;
    const response = await this.authService.sendPasswordResetLink(this.updatePasswordForm.value);
    this.email = this.updatePasswordForm.get('email').value;
    this.completed = response;
  }
}