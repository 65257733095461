import {environment}  from '../../environments/environment';
import {Injectable}   from '@angular/core';
import {HttpClient}   from '@angular/common/http';

@Injectable({providedIn:'root'})
export class AdminTransaction {

  constructor(
    protected http: HttpClient,
  ) {}

  async createTransaction (type, amount, user){
    let url = environment.apiUrl + 'admin/transaction/create';
    return await this.http.post(url,{
      type:type,
      amount:amount,
      user:user,
      label:"admin"
    }).toPromise();
  }

}
