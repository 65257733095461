import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MessageService {

  delay(milisec) {
    return new Promise(resolve => {
        setTimeout(() => { resolve('') }, milisec);
    })
  }

  private messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();

  private messageState  = new BehaviorSubject(false);
  currentState = this.messageState.asObservable();

  constructor() { }

  async alertMessage(message: string) {

    this.messageSource.next(message)
    this.messageState.next(true);

    await this.delay(5000);
    this.messageSource.next('');
    this.messageState.next(false);
  }
}