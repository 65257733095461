import {environment}  from '../../environments/environment';
import {Injectable}   from '@angular/core';
import {HttpClient}   from '@angular/common/http';

@Injectable({providedIn:'root'})
export class MachineService {

  constructor(
    protected http: HttpClient,
  ) {}

  async ping (machine){
    let url = environment.apiUrl + 'machine/ping' + machine;
    return await this.http.get(url).toPromise();
  }

  async createOrder (machine, value){
    let url = environment.apiUrl + 'machine/createOrder' + machine;
    return await this.http.post(url,{amount:value}).toPromise();
  }

  async confirmOrder (machine, orderLabel){
    let url = environment.apiUrl + 'machine/confirmOrder' + machine;
    return await this.http.post(url,{label:orderLabel}).toPromise();
  }

  async cancelOrder (machine, orderLabel){
    let url = environment.apiUrl + 'machine/cancelOrder' + machine;
    return await this.http.post(url,{label:orderLabel}).toPromise();
  }
}

