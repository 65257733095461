import {Component,OnInit}       from '@angular/core';
import {HttpClient}   from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';

@Component({templateUrl: 'transactions.component.html'})
export class TransactionsComponent implements OnInit {
  constructor(
    protected http: HttpClient,
  ) {}
  transactions;
  dateFrom;
  dateTo;
  dateRange;

  async ngOnInit() {
    this.getTodayValues();
    console.log(this.transactions);
  }
  
  async search (){
    var request = "sort%5B0%5D=createdAt%2CDESC"
    var formattedDateFrom = this.getMysqlFromDate(this.dateFrom.toString())
    var formattedDateTo = this.getMysqlToDate(this.dateTo.toString());
    request += "&filter%5B0%5D=createdAt%7C%7C%24gte%7C%7C" + formattedDateFrom + "&filter%5B1%5D=createdAt%7C%7C%24lte%7C%7C" + formattedDateTo;
    this.transactions = await this.http.get(environment.apiUrl + 'admin/transaction/?' + request).toPromise();
  }
  
  getMysqlDate(date) {
   let myDate = new Date(date).toLocaleString("et-EE", {
      localeMatcher: "best fit",
      timeZoneName: "short"
    });;
    return myDate.replace(',', ' ').replace('GMT +3', ' ').replace('GMT +2', ' ');
  }

  async getTodayValues(){
    this.dateFrom = new Date();
    this.dateTo = new Date();
    await this.search();
    this.dateRange = "today";
  }

  async getYesterdayValues(){
    let dateObj = new Date();
    dateObj.setUTCDate(dateObj.getDate()-1);
    this.dateFrom = dateObj;
    dateObj = new Date();
    dateObj.setUTCDate(dateObj.getDate()-1);
    this.dateTo = dateObj;
    await this.search();
    this.dateRange = "yesterday";
  } 

  async getCurrentMonthValues(){
    let dateObj = new Date();
    dateObj.setUTCDate(1);
    this.dateFrom = dateObj;
    dateObj = new Date();
    this.dateTo = dateObj;
    await this.search();
    this.dateRange = "currentMonth";
  }
  async getLastMonthValues(){
    let dateObj = new Date();
    dateObj.setUTCDate(1);
    dateObj.setUTCMonth(dateObj.getMonth()-1);
    this.dateFrom = dateObj;
    dateObj = new Date();
    dateObj.setUTCDate(1);
    dateObj.setUTCDate(dateObj.getDate()-1);
    this.dateTo = dateObj;
    await this.search();
    this.dateRange = "lastMonth";
  }
  
  getMysqlFromDate(date: string) {
    let dateObj = new Date(date);
    dateObj.setUTCDate(dateObj.getDate());
    dateObj.setUTCHours(0);
    dateObj.setUTCMinutes(0);
    dateObj.setUTCSeconds(1);
    return dateObj
      .toJSON()
      .slice(0, 19)
      .replace('T', '%20')
      .replace(':', '%3A')
      .replace(':', '%3A');
  }

  getMysqlToDate(date: string) {
    let dateObj = new Date(date);
    dateObj.setUTCDate(dateObj.getDate());
    dateObj.setUTCHours(23);
    dateObj.setUTCMinutes(59);
    dateObj.setUTCSeconds(59);
    return dateObj
      .toJSON()
      .slice(0, 19)
      .replace('T', '%20')
      .replace(':', '%3A')
      .replace(':', '%3A');
  }

  exportCSV(){
    console.log("exportCSV");
    console.table(this.transactions);

    let exportRows = [];

    this.transactions.forEach((trans) => {
      let row = {};
      row['createdAt'] = this.getMysqlDate(trans.createdAt);
      row['type'] = trans.type;
      row['amount'] = trans.amount; 
      row['user'] = trans.user;
      row['label'] = trans.label;
      exportRows.push(row);
    });

    if (exportRows.length) { this.downloadFile(exportRows, 'transactions.csv');} else {}
  }


  downloadFile(data: any, fileName) {
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(data[0]);
    let csv = data.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','),
    );
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    let blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, fileName);
  }
}