import {Injectable}        from '@angular/core';
import {AuthService}       from '../../services';
import {Observable}        from 'rxjs';
import {catchError, map}   from 'rxjs/operators';
import {AbstractControl, 
        AsyncValidator, 
        ValidationErrors}  from '@angular/forms';

// noinspection JSAnnotator
@Injectable({
  providedIn: 'root'
})
export class EmailTakenValidator implements AsyncValidator {

  constructor(
    public authService: AuthService
  ) { }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.authService.emailExists(control.value).pipe(
      map(exists => (exists ? { emailExists: true } : null)),
      catchError(() => null)
    );
  }

}
