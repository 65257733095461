<div *ngIf = "loadingPayment" class="cardBoxOne">
  <div class = "cardOne">
    <mat-spinner diameter = 160></mat-spinner>
  </div>
</div>

<div *ngIf="paymentSuccess != null" class="buyCredit">
  <mat-icon *ngIf="paymentSuccess == true" class="payment success">check</mat-icon>
  <mat-icon *ngIf="paymentSuccess == false" class="payment failed">close</mat-icon>
</div>

<div *ngIf="paymentSuccess == true" class="buyCredit comment success">{{paymentMessage}}</div>
<div *ngIf="paymentSuccess == false" class="buyCredit comment failed">{{paymentMessage}}</div>

<button *ngIf="paymentSuccess != null" routerLink="/home" class="homeButton next">OK</button>