import {Injectable}             from '@angular/core';
import {Observable}             from 'rxjs/Observable';
import {AuthService}            from '../services/auth.service';
import {CanActivate, 
        ActivatedRouteSnapshot, 
        RouterStateSnapshot }   from '@angular/router';

@Injectable()
export class BeforeLoginGuard implements CanActivate {
  constructor(private authService: AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return !this.authService.isLoggedIn();
  }
}
