import {environment}  from '../../environments/environment';
import {Injectable}   from '@angular/core';
import {HttpClient}   from '@angular/common/http';

@Injectable({providedIn:'root'})

export class InvoiceService {
  constructor(
    protected http: HttpClient,
  ) {}

  async getInvoicePdf (orderID){
    const url = environment.apiUrl + 'invoice/getPdf'; 
    const response = await this.http.post(url, { orderID: orderID }, { responseType: 'blob' }).toPromise();
    const blob = new Blob([response], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Invoice.pdf';
    link.click();
  }

}