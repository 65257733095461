import {Component, OnInit}      from '@angular/core';
import {Router}                 from '@angular/router';
import {first}                  from 'rxjs/operators';
import {AuthService}            from '../../services';
import {EmailTakenValidator}    from './email.taken.validator';
import {FormBuilder, 
        FormGroup, 
        Validators}             from '@angular/forms';


@Component({templateUrl: 'register.component.html'})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    confirmed = false;
    showPasswordchecked = false;
    showConfirmPasswordchecked = false;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        public authService: AuthService,
        public emailTakenValidator: EmailTakenValidator,
        //private authenticationService: AuthenticationService,
        //private userService: UserService,
        //private alertService: AlertService
    ) { 
        // redirect to home if already logged in

/*
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
*/

    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstName: ['', [Validators.required,Validators.pattern('^[A-Za-zÄäÖöÜüÕõ\-]+$')]],
            lastName: ['', [Validators.required,Validators.pattern('^[A-Za-zÄäÖöÜüÕõ\-]+$')]],
            email: ['', [Validators.required, Validators.email], [this.emailTakenValidator]],
            password: ['', [
              Validators.required,
              Validators.pattern('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-@!$%^&*()_+|~=`{}\\[\\]:";<>?,.\/]).{8,}')
            ]
            ],
            confirm_password: ['', [Validators.required]],
        }, { validators: [this.confirmPasswordValidator] });
    }

    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.confirmed = true;
        this.loading = true;
        this.authService.register(this.registerForm.value)
            .pipe(first())
            .subscribe(
                data => {
                    //this.alertService.success('Registration successful', true);
                    //this.router.navigate(['/login']);
                },
                error => {
                    //this.alertService.error(error);
                    this.loading = false;
                });

    }

    showHidePassword() {
        if (this.showPasswordchecked) this.showPasswordchecked = false;
        else this.showPasswordchecked = true;
    }

    showHideConfirmPassword() {
        if (this.showConfirmPasswordchecked) this.showConfirmPasswordchecked = false;
        else this.showConfirmPasswordchecked = true;
    }

    confirmPasswordValidator(formGroup) {
        if (formGroup.get('password').value != formGroup.get('confirm_password').value) {
          formGroup.get('confirm_password').setErrors({ 'passwords_not_match': true });
        }
      }
}
