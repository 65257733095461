<div class="second_header">
  <div class="back_button" routerLink="/home">←back</div>
  <div class="title_container">
    <h2>Users</h2>
  </div>
</div>
<div class="row clearfix">
  <form [formGroup]="userSearchForm" (ngSubmit)="onSearchSubmit()">
    <label for="email" style = "display:flex">Search user</label>
    <input type="text" style = "margin-bottom:15px" formControlName="email" class="form-control" 
    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
    <input *ngIf = "!loadingUsers" class="homeButton logIn" style = "margin-bottom:15px" type="submit" value="Search"/>
  </form>
  <button *ngIf = "!loadingUsers" (click)="getAllUsers()" class="homeButton" style = "border-radius: 50px;">Load all users</button>
  <div *ngIf = "loadingUsers" class="cardBoxOne">
    <div class = "cardOne">
      <mat-spinner *ngIf = "loadingUsers" diameter = 160></mat-spinner>
    </div>
  </div>
  <div  *ngFor="let user of users; let i = index;">
    <div class="listUser header">
      <mat-icon *ngIf="!userDetailedInfo[this.users.indexOf(user)]" class="listItem icon" (click) = "showUserDetailedInfo(user)">keyboard_arrow_right</mat-icon> 
      <mat-icon *ngIf="userDetailedInfo[this.users.indexOf(user)]" class="listItem icon" (click) = "hideUserDetailedInfo(user)">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="user.status == 'confirm'" class="listItem icon blue" >verified_user</mat-icon>
      <mat-icon *ngIf="user.status == 'pending'" class="listItem icon yellow" >query_builder</mat-icon>
      <div class="listItem">{{user.email}}</div>
      <div class="listItem value"> 
        <div class = "balance_Info">
          <b *ngIf="(user.transactions.toFixed(2) - user.orders.toFixed(2)) != user.balance.toFixed(2)" class = "userHeader_balance warning">(!)</b>
          <b class = "userHeader_balance">{{user.balance.toFixed(2)}}</b> <br>
          <div>
            <b class = "userHeader_orders" >{{user.orders.toFixed(2)}}</b>
            <b class = "userHeader_transactions" >{{user.transactions.toFixed(2)}}</b>
          </div> 
        </div>
      </div>
    </div>
    <div *ngIf="userDetailedInfo[this.users.indexOf(user)]" class="listUser detailedInfo" >
      <div class="row">
        <div class="listItem key detailed">ID:</div>
        <div class="listItem value detailed" ><b>{{user.id}}</b></div>
      </div>
      <div class="row">
        <div class="listItem key detailed">First name:</div>
        <div class="listItem value detailed" ><b>{{user.firstName}}</b></div>
      </div>
      <div class="row">
        <div class="listItem key detailed">Last name:</div>
        <div class="listItem value detailed" ><b>{{user.lastName}}</b></div>
      </div>
      <div class="row">
        <div class="listItem key detailed">Balance:</div>
        <div class="listItem value detailed balance" ><b>{{user.balance.toFixed(2)}}</b></div>
        <div *ngIf="!balanceEditMode[this.users.indexOf(user)]" class="listItem value detailed" (click)="initBalanceEdit(user)" ><mat-icon class="edit">edit</mat-icon></div>
        <div *ngIf="balanceEditMode[this.users.indexOf(user)]" class="listItem value detailed" (click)="cancelBalanceEdit(user)" ><mat-icon class="edit">close</mat-icon></div> 
        <div *ngIf="balanceEditMode[this.users.indexOf(user)]" class="listItem value detailed" (click)="confirmBalanceEdit(user)" > <i><b>confirm</b></i> <mat-icon class="edit">check</mat-icon></div> 
      </div>
      <div *ngIf="balanceEditMode[this.users.indexOf(user)]" class="row balanceEdit">
        <mat-select class="listItem key detailed transactionType" [(value)]="selectedType[this.users.indexOf(user)]">
            <mat-option *ngFor="let type of transactionTypes" [value]="type.value">
                {{type.value}}
            </mat-option>
        </mat-select>
        <div class="listItem value detailed">
          <input class = "transactionAmount" matInput type = "number" [value] = "amounts[this.users.indexOf(user)]" (input)="onAmountChange($event.target.value,user)" placeholder="Amount">
        </div>
      </div>

      <div class="row">
        <div class="listItem key detailed">E-mail:</div>
        <div class="listItem value detailed" ><b>{{user.email}}</b></div>
      </div>
      <div class="row">
        <div class="listItem key detailed">Role:</div>
        <div class="listItem value detailed" ><b>{{user.role}}</b></div>
      </div>
      <div class="row">
        <div class="listItem key detailed">Status:</div>
        <div class="listItem value detailed status" ><b>{{user.status}}</b></div>
        <div *ngIf="!statusEditMode[this.users.indexOf(user)]" class="listItem value detailed statusEdit"><mat-icon class="edit" (click)="initStatusEdit(user)">edit</mat-icon></div>
        <div *ngIf="statusEditMode[this.users.indexOf(user)]" class="listItem value detailed statusEdit"><mat-icon class="edit" (click)="cancelStatusEdit(user)">close</mat-icon></div> 
        <div *ngIf="statusEditMode[this.users.indexOf(user)]" class="listItem value detailed"> <i><b>confirm</b></i> <mat-icon class="edit" (click)="confirmStatusEdit(user)">check</mat-icon></div> 
      </div>
      <div *ngIf="statusEditMode[this.users.indexOf(user)]" class="row balanceEdit">
        <mat-select class="listItem key detailed transactionType" [(value)]="selectedStatus[this.users.indexOf(user)]">
            <mat-option *ngFor="let status of statusValues" [value]="status.value">
                {{status.value}}
            </mat-option>
        </mat-select>
      </div>
      <div class="row">
        <div class="listItem key detailed">Created:</div> 
        <div class="listItem value detailed"><b>{{this.getMysqlDate(user.createdAt)}}</b></div> 
      </div>
      <div class="row">
        <div class="listItem key detailed">Transactions:</div> 
        <div class="listItem value detailed"><b>{{user.transactions.toFixed(2)}}</b></div> 
      </div>
      <div class="row">
        <div class="listItem key detailed">Orders:</div> 
        <div class="listItem value detailed"><b>{{user.orders.toFixed(2)}}</b></div> 
      </div>
    </div>
  </div>
</div>