import {Component, OnInit}                   from '@angular/core';
import {HttpClient}   from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({ templateUrl: 'history.component.html'})
export class HistoryComponent implements OnInit {
  history;
  constructor(
    protected http: HttpClient,
  ) {}
  async ngOnInit() {
    this.history = await this.http.get(environment.apiUrl + 'auth/history').toPromise();
    console.log(this.history); 
  }
  getMysqlDate(date) {
    let myDate = new Date(date).toLocaleString("et-EE", {
       localeMatcher: "best fit",
       timeZoneName: "short"
     });;
     return myDate.replace(',', ' ').replace('GMT +3', ' ').replace('GMT +2', ' ');
   }
}