import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators}  from '@angular/forms';
import { AuthService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';

AuthService
@Component({
  selector: 'app-response-reset',
  templateUrl: './response-reset.component.html'
})
export class ResponseResetComponent implements OnInit {

  updatePasswordForm: FormGroup;
  submitted = false;
  completed = false;
  public message: string;
  showPasswordchecked = false;
  showConfirmPasswordchecked = false;



  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(){
    this.updatePasswordForm = this.formBuilder.group({
      email: [''],
      password: ['',[
        Validators.required, 
        Validators.minLength(8),
        Validators.pattern('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-@!$%^&*()_+|~=`{}\\[\\]:";<>?,.\/]).{8,}')
      ]],
      passwordConfirm: ['',[Validators.required ]],
      passwordResetToken: ['']
    }, { validators: [this.confirmPasswordValidator] });

    this.route.queryParams.subscribe(params => {
      this.updatePasswordForm.get('passwordResetToken').setValue(params['passwordResetToken']);
      this.updatePasswordForm.get('email').setValue(params['email']);
    });
  }
  get f() { return this.updatePasswordForm.controls; }

  onSubmit(){
    this.submitted = true;
    if (this.updatePasswordForm.invalid) return;

    this.authService.changePassword(this.updatePasswordForm.value).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  handleResponse(data) {
    this.submitted = true;

    if (data['access_token']) {
      this.authService.setToken(data['access_token']);
      this.message = 'Password was changed, but you have been logged in already! How cool is that?!'
      this.completed = true;
      this.router.navigateByUrl('/home');
    } else {
      this.message = 'Could not change your password, mate.. sorry :)'
    }
  }

  showHidePassword() {
    if (this.showPasswordchecked) this.showPasswordchecked = false;
    else this.showPasswordchecked = true;
  }

  showHideConfirmPassword() {
      if (this.showConfirmPasswordchecked) this.showConfirmPasswordchecked = false;
      else this.showConfirmPasswordchecked = true;
  }

  confirmPasswordValidator(updatePasswordForm) {
    if (updatePasswordForm.get('password').value != updatePasswordForm.get('passwordConfirm').value) {
      updatePasswordForm.get('passwordConfirm').setErrors({ 'passwords_not_match': true });
    }
  }

  get password_confirmation() {
    return this.updatePasswordForm.get('passwordConfirm');
  }

  get password() {
    return this.updatePasswordForm.get('password');
  }

  handleError(error) {
    console.log(error);
  }
}