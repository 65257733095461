import {Component,OnInit} from '@angular/core';
import {Router}           from '@angular/router';

@Component({templateUrl: 'wash.component.html'})
export class WashComponent implements OnInit {
  
  kadakaSelected: boolean;
  laagnaSelected: boolean;

  constructor (
    private router: Router,
  ) {}

  ngOnInit() {}

  onKadakaClick(){
    //this.router.navigateByUrl('/kadaka');
    this.kadakaSelected = true;
    this.laagnaSelected = false;
  }

  onNextClick(){
    if(this.kadakaSelected)this.router.navigateByUrl('/kadaka');
    if(this.laagnaSelected)this.router.navigateByUrl('/laagna');
  }
  
  onLaagnaClick(){
    //this.router.navigateByUrl('/laagna');
    this.kadakaSelected = false;
    this.laagnaSelected = true;
  }
}