import {Component, OnInit}                   from '@angular/core';
import { AuthService } from 'src/app/services';
import {Router}             from '@angular/router';

@Component({ templateUrl: 'profile.component.html'})
export class ProfileComponent implements OnInit {
  ngOnInit() {}

  public userData;
  constructor (
    private authService: AuthService,
    private router: Router,
  ) {
    this.authService.subscribeToUser().subscribe(user => {
      if (user) {
        this.userData = user;
      } else {
        this.userData = null;      
      }
    });
  }

  logout(event: MouseEvent) {
    event.preventDefault();
    this.authService.logout();
    this.router.navigateByUrl('/');
  }
}